import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

import { MobileUserIdTextContainer, UserIdTextContainer } from './common/header-styles';

interface TenantPrefixLabelProps {
  tenantUserId?: string | number;
  isMobile?: boolean;
}

export function useTenantPrefix() {
  const tenantMetadata = useStaticQuery(graphql`
    query UserIdPrefixQuery {
      site {
        siteMetadata {
          userIdPrefix
        }
      }
    }
  `);
  return get(tenantMetadata, 'site.siteMetadata.userIdPrefix', '');
}

export const TenantPrefixLabel = (props: TenantPrefixLabelProps) => {
  const tenantPrefix = useTenantPrefix();
  const tenantUserInfo = props.tenantUserId ? `${tenantPrefix} - ${props.tenantUserId}` : null;
  return props.isMobile ? (
    <MobileUserIdTextContainer>{tenantUserInfo}</MobileUserIdTextContainer>
  ) : (
    <UserIdTextContainer>{tenantUserInfo}</UserIdTextContainer>
  );
};
