import axios from 'axios';

import { createError } from '../error';
import responseParser from '../response-parser';
import {
  ICreateMessageFormData,
  ICreateReportMessageFormData,
  IReplyMessageFormData,
  IMessageThread,
} from '../../types/models/message-center';
import { IGetMessagesRequestParams, IGetMessagesDTO } from '../../types/dto/message-center';
import { API_URL } from '../../constants';

/**
 * Returns all the threads for the user
 *
 * @export
 * @param {IGetMessagesRequestParams} params
 * @returns {Promise<IMessageThread[]>}
 */
export async function getMessages(params: IGetMessagesRequestParams): Promise<IGetMessagesDTO> {
  const url = `${API_URL}/messages`;

  try {
    const response = await axios.get(url, {
      params,
    });
    const { items: data, stats }: { items: IGetMessagesDTO['data']; stats: IGetMessagesDTO['stats'] } =
      responseParser(response);
    return {
      data,
      stats,
      total: response.data.totalCount,
    };
  } catch (err) {
    throw createError(err);
  }
}
getMessages.QUERY_KEY = 'user:getMessages';

/**
 * Returns the number of unread messages for a non-admin user
 *
 * @export
 * @returns {Promise<number>}
 */
export async function getUserMessageCount(): Promise<number> {
  const url = `${API_URL}/messages`;

  try {
    const response = await axios.get(url, {
      params: { getUnreadLen: true },
    });
    const { count } = responseParser(response);
    return count;
  } catch (err) {
    throw createError(err);
  }
}
getUserMessageCount.QUERY_KEY = 'user:getMessageCount';

/**
 * Returns the thread for the given `threadId`
 *
 * @export
 * @param {string} threadId
 * @returns {Promise<IMessageThread>}
 */
export async function getMessageDetails(threadId: string): Promise<IMessageThread> {
  const url = `${API_URL}/messages/${threadId}`;

  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err) {
    throw createError(err);
  }
}
getMessageDetails.QUERY_KEY = 'user:getMessageDetails';

/**
 * Creates a new message thread
 *
 * @export
 * @param {ICreateMessageFormData} payload
 * @returns {Promise<void>}
 */
export async function createMessage(payload: ICreateMessageFormData): Promise<void> {
  const url = `${API_URL}/messages`;

  try {
    await axios.post(url, payload);
  } catch (err) {
    throw createError(err);
  }
}

/**
 * Reply to the existing thread
 *
 * @export
 * @param {string} threadId
 * @param {IReplyMessageFormData} payload
 * @returns {Promise<void>}
 */
export async function replyToMessage(threadId: string, payload: IReplyMessageFormData): Promise<void> {
  const url = `${API_URL}/messages/${threadId}`;

  try {
    await axios.post(url, payload);
  } catch (err) {
    if (err instanceof Error) {
      throw createError(err);
    }
  }
}

/**
 * Creates a new message thread
 *
 * @export
 * @param {ICreateReportMessageFormData} payload
 * @returns {Promise<void>}
 */
export async function createReportMessage(payload: ICreateReportMessageFormData): Promise<void> {
  const url = `${API_URL}/messages/report-user`;

  try {
    await axios.post(url, payload);
  } catch (err) {
    throw createError(err);
  }
}
